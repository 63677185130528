/* You can add global styles to this file; and also import other style files */

.tda-body {
  --modus-navbar-brand-logo-filter: none !important;
  margin: 0px;
  font-family: var(--font-family-sans-serif);
  background-color: var(--tda-background) !important;
  color: var(--tda-text) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  #root {
    overflow: hidden;
  }
}
